
import { Field } from "vee-validate";
import { defineComponent, ref } from "vue";
import emitter from "tiny-emitter/instance";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "Pergunta",
  components: { Field },
  props: {
    pergunta: Object,
    imagens: Array,
    analiseId: String
  },
  setup(context) {
    const resposta = ref<any>(context.pergunta);
    const evidencias = ref<any>(context.imagens);
    const analiseId = ref<any>(context.analiseId);
    resposta.value.imagens = [];
    const input = ref<HTMLInputElement>();
    const previewList = ref<any>([]);
    const submitButton = ref<any>([]);

    const atualizarResposta = () => {
      console.log("resposta", resposta.value);
      emitter.emit("atualizarResposta", resposta.value);
    };

    const enviarImagem = (imagem, itemId, categoria) => {
      emitter.emit("enviarImagem", imagem, itemId, categoria);
    };

    const isImage = (fileUrl) => {
      const imgExtensions = ["jpg", "png", "jpeg", "bmp"];
      const videoExtensions = ["mkv", "mp4", "webm"];
      const lastDot = fileUrl.lastIndexOf(".");

      let ext = fileUrl.substring(lastDot + 1);
      const refresh = ext.lastIndexOf("?");
      if (refresh > -1) {
        ext = ext.substring(0, refresh);
      }

      if (imgExtensions.includes(ext)) {
        return true;
      } else {
        return false;
      }
    };

    const removeImagem = (index) => {
      previewList.value.splice(index, 1);
      resposta.value.imagens.splice(index, 1);
      atualizarResposta();
    };

    const removeEvidencia = (id, index) => {
      Swal.fire({
        title: "Deseja realmente excluir essa evidência?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, deletar!",
      }).then((result) => {
        if (result.isConfirmed) {
          evidencias.value.splice(index, 1);
            ApiService.delete("/evidencia/excluir/" + id).then(({ data }) => {
              console.log("data", data);
            });
          }
      });
    };

    const girar = (evidencia, index) => {
      if (submitButton.value[index]) {
        // Activate indicator
        submitButton.value[index].setAttribute("data-kt-indicator", "on");
      }
      ApiService.get(`evidencia/girar?id=${evidencia.id}`).then(({ data }) => {
        evidencia.url = data;
        submitButton.value[index].removeAttribute("data-kt-indicator");
      });
    }

    const compress = (img, size) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const initSize = img.src.length;
      const width = img.width;
      const height = img.height;
      canvas.width = width;
      canvas.height = height;
      //Base color
      if (ctx !== null) {
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, width, height);
      }
      //Minimum compression
      const ndata = canvas.toDataURL("image/jpeg", size);
      console.log("* * * * * * * * * *");
      // console.log(ndata)
      console.log(ndata.length / 1024);
      return ndata;
    };

    const onFileAdd = (event) => {
      const input = event.target;
      let count = input.files.length;
      let index = 0;
      if (input.files) {
        while (count--) {
          const formData = new FormData();

          formData.append("evidencia", input.files[index]);
          formData.append("analiseId", analiseId.value);
          formData.append("itemId", resposta.value.itemId);
          formData.append("categoria", resposta.value.categoria);

          ApiService.post("/evidencia/cadastrar2", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(({ data }) => {
            evidencias.value.push({
              url: data.item2,
              id: data.item1
            });
          });
          index++;
        }
      }
    };

    // const onFileAdd2 = (event) => {
    //   const input = event.target;
    //   let count = input.files.length;
    //   let index = 0;
    //   if (input.files) {
    //     while (count--) {
    //       const reader = new FileReader();
    //       reader.onload = (e: any) => {
    //         const result = e.target.result;
    //         previewList.value.push(result);
    //         if (result.indexOf("data:image") >= 0) {
    //           const img = new Image();
    //           img.src = e.target.result;
    //           img.onload = function () {
    //             const data = compress(img, 0.5);
    //             const imagem = data.replace(/^data:image\/[a-z]+;base64,/, "");
    //             resposta.value.imagens.push(imagem);
    //             enviarImagem(
    //               imagem,
    //               resposta.value.itemId,
    //               resposta.value.categoria
    //             );
    //             atualizarResposta();
    //           };
    //         } else {
    //           const imagem = e.target.result.replace(
    //             /^data:video\/[a-z,0-9]+;base64,/,
    //             ""
    //           );
    //           resposta.value.imagens.push(imagem);
    //           enviarImagem(
    //             imagem,
    //             resposta.value.itemId,
    //             resposta.value.categoria
    //           );
    //           atualizarResposta();
    //         }
    //       };
    //       reader.readAsDataURL(input.files[index]);
    //       index++;
    //     }
    //   }
    // };

    return {
      Field,
      resposta,
      input,
      onFileAdd,
      evidencias,
      previewList,
      atualizarResposta,
      removeImagem,
      removeEvidencia,
      isImage,
      girar,
      submitButton
    };
  },
});
